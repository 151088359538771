<template>
  <div>
    <div class="columns is-gapless">
      <div class="column is-narrow hide-for-print">
        <div id="datasheet_builder" style="position: relative">
          <b-loading :is-full-page="false" :active.sync="loading" :can-cancel="false">
            <div style="flex-direction: column; align-self: flex-start; text-align: center; z-index: 998; padding-top: 100px;">
              <div class="loading-icon" />
              <div class="padding-xy-3 has-text-black" style="margin-top: 50px">
                Loading Data
              </div>
            </div>
          </b-loading>

          <div v-if="!loading" class="left_col padding-xy-4">
            <div class="field">
              <label v-if="languageSelect">Language</label>
              <b-select v-model="languageSelect" expanded placeholder="Language">
                <option value="english">
                  English
                </option>
                <option value="spanish">
                  Spanish
                </option>
                <option value="chinese">
                  Chinese
                </option>
                <option value="russian">
                  Russian
                </option>
              </b-select>
            </div>
            <div v-if="languageSelect" class="field">
              <label v-if="connectionSelect">Connection</label>
              <b-select v-model="connectionSelect" expanded placeholder="Connection" @input="changeSheet('default')">
                <option disabled="disabled" :value="null">
                  Connection
                </option>
                <option v-for="c in connections" :key="c.id" :value="c.id">
                  {{ c.name }}
                </option>
              </b-select>
            </div>
            <div v-if="connectionSelect" class="field">
              <label v-if="sizeSelect">Size</label>
              <b-select v-model="sizeSelect" expanded placeholder="Size" @input="changeSheet('size')">
                <option disabled="disabled" :value="null">
                  Size
                </option>
                <option v-for="s in sizesFiltered" :key="s.id" :value="s.id">
                  {{ s.size }}
                </option>
              </b-select>
            </div>
            <div v-if="sizeSelect" class="field">
              <label v-if="weightSelect">Weight</label>
              <b-select v-model="weightSelect" expanded placeholder="Weight" @input="changeSheet('weight')">
                <option disabled="disabled" :value="null">
                  Weight
                </option>
                <option v-for="w in weightsFiltered" :key="w.id" :value="w.id">
                  {{ w.weight }}
                </option>
              </b-select>
            </div>
            <div v-if="weightSelect" class="field">
              <label v-if="gradeSelect">Grade</label>
              <b-select v-model="gradeSelect" expanded placeholder="Grade" @input="changeSheet('grade')">
                <option disabled="disabled" :value="null">
                  Grade
                </option>
                <option v-for="g in gradesFiltered" :key="g.id" :value="g.id">
                  {{ g.name }}
                </option>
              </b-select>
            </div>
            <div v-if="gradeSelect && connectionType !== 'PlainEnd'" class="field">
              <label v-if="frictionSelect">Friction</label>
              <b-select v-model="frictionSelect" expanded placeholder="Friction" @input="changeSheet('friction')">
                <option disabled="disabled" :value="null">
                  Friction
                </option>
                <option value="Api">
                  API Modified
                </option>
                <option v-if="hasClearRun" value="Clr">
                  Clear-Run
                </option>
              </b-select>
            </div>
            <div v-if="frictionSelect && connectionType !== 'SemiFlush'" class="field">
              <label v-if="couplingSelect">Coupling</label>
              <b-select v-model="couplingSelect" expanded placeholder="Coupling" @input="changeSheet('coupling')">
                <option disabled="disabled" :value="null">
                  Coupling
                </option>
                <option v-for="couple in couplingData" :key="couple.id" :value="couple.id">
                  {{ couple.name }}
                </option>
              </b-select>
            </div>
            <div v-if="couplingSelect && connectionType !== 'SemiFlush'" class="field">
              <label v-if="bevelSelect">Bevel</label>
              <b-select v-model="bevelSelect" expanded placeholder="Bevel">
                <option disabled="disabled" :value="null">
                  Bevel
                </option>
                <option value="45">
                  45&deg; Standard
                </option>
                <option value="20">
                  20&deg; Special
                </option>
              </b-select>
            </div>
            <div class="columns is-mobile" style="margin-top: 0px">
              <div class="column has-text-centered">
                <b-button :disabled="!gradeSelect" class="is-success padding-y-2 is-fullwidth" style="height: auto" @click="createPDF()">
                  <div><b-icon icon="download" /></div>
                  <div>Download</div>
                </b-button>
                <small class="is-block padding-y-2 has-text-grey">Compressed PDF</small>
              </div>
              <div class="column has-text-centered">
                <b-button :disabled="!gradeSelect" class="is-success padding-y-2 is-fullwidth" style="height: auto" @click="printPdf()">
                  <div><b-icon icon="print" /></div>
                  <div>Print</div>
                </b-button>
                <small class="is-block padding-y-2 has-text-grey">Full-resolution</small>
              </div>
            </div>
            <div class="has-text-centered margin-y-2">
              <b-button icon-left="undo" type="is-transparent has-text-grey-light" @click="resetSheet">
                Reset
              </b-button>
            </div>
            <div v-if="hasPermissionToShowFormulas" class="has-text-centered margin-y-2">
              <small>
                <b-checkbox v-model="showFormulas">
                  Show tooltip formulas
                </b-checkbox>
              </small>
            </div>
            <div class="field has-text-centered" style="margin-bottom: 30px; font-size: 10px">
              <small>Version 3.0.2.2</small>
            </div>
            <div v-if="finishedSelection" style="font-size: 13px; line-height: 1.4; color: #999; margin-top: auto; padding-top: 30px;">
              Contact JFETC for sizes or grades not currently listed.
            </div>
          </div>
        </div>
      </div>
      <div class="column" style="overflow: auto">
        <div id="datasheet_wrapper">
          <div id="datasheet_page">
            <div id="pdf_wrapper">
              <div id="watermark">
                Generated by <br> {{ profile.firstName + ' ' + profile.lastName }}
              </div>
              <div id="datasheet_header" class="padding-y-3 padding-x-4">
                <div class="columns is-mobile">
                  <div class="sidebar column is-narrow" style="padding-right: 30px">
                    <img style="width: 100px; margin: 0 auto;" src="@/assets/images/JFE-logo-stacked.png">
                  </div>
                  <div class="column">
                    <div class="columns is-mobile is-vcentered" style="margin-bottom: 0">
                      <div v-if="connectionSelect" class="column is-narrow">
                        <div v-if="imageConnection">
                          <img
                            :src="imageConnection.screenshotUrl"
                            crossOrigin="anonymous"
                            style="width: 140px">
                        </div>
                      </div>
                      <div class="column is-narrow" />
                      <div class="column has-text-right" style="margin-right: 10px;">
                        <div class="has-text-black has-text-weight-bold is-size-5">
                          Data Sheet
                        </div>
                        <div class="has-text-grey is-size-6">
                          {{ dateHeader }}
                        </div>
                        <div class="is-hidden is-size-6">
                          Uncontrolled Document
                        </div>
                      </div>
                    </div>

                    <div v-if="sizeSelect" id="quick_data" class="columns is-mobile" :class="{'justified' : finishedSelection}">
                      <div v-if="sizeSelect" class="column is-narrow">
                        <label>{{ dataLanguages['size'][languageSelect] }}</label>
                        <strong>{{ size.size }} in</strong>
                        <small>{{ inchmm(size.size) }} mm</small>
                      </div>
                      <div v-if="weightSelect" class="column is-narrow">
                        <label>{{ dataLanguages['weight'][languageSelect] }}</label>
                        <strong>{{ weight.weight }} lb/ft</strong>
                        <small>{{ numberFormat(weightHeader(weight.weight), 2) }} kg/m</small>
                      </div>
                      <div v-if="gradeSelect" class="column is-narrow">
                        <label>{{ dataLanguages['grade'][languageSelect] }}</label>
                        <strong>{{ grade.name }}</strong>
                      </div>
                      <div v-if="couplingSelect" class="column is-narrow">
                        <label>{{ dataLanguages['coup'][languageSelect] }}</label>
                        <strong>{{ couplingLabel }}</strong>
                      </div>
                      <!-- <div v-if="bevelSelect" class="column is-narrow">
                        <label>Bevel</label>
                        <strong v-if="bevelSelect == 45">45&deg; Standard</strong>
                        <strong v-if="bevelSelect == 20">20&deg; Special</strong>
                      </div> -->
                      <div v-if="frictionSelect" class="column is-narrow">
                        <label>{{ dataLanguages['ff'][languageSelect] }}</label>
                        <strong>{{ frictionFactor() }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="padding-xy-3" style="padding-top: 5px">
                <div class="columns is-mobile">
                  <div class="sidebar column is-narrow">
                    <div v-if="gradeSelect" class="sidebar_block">
                      <!-- <div class="header">
                        <span v-if="gradeSelect">{{ gradeSelect }}</span>
                        <span v-if="gradeSelect && couplingSelect" class="padding-x-2">&bull;</span>
                        <span v-if="couplingSelect">{{ couplingSelect }}%</span>
                      </div> -->
                      <ModelViewer
                        class="model-viewer-container"
                        :model-type="connectionType"
                        :colors="{...gradeStripes, tncCouplingStripe4: 'none'}"
                        :special-coupling="specialCoupling"
                        :printing="printingPdf"
                        :config="{gui: false, controls: true, zoom: 1.3, bgColor: 0xffffff}" />
                      <!-- <StripingDiagram
                        :type="connectionType"
                        :grade-stripes="gradeStripes"
                        label=""
                        :datasheet="true"
                        :special-coupling="specialCoupling"
                        style="transform: scale(0.9, 0.8)" /> -->
                    </div>
                    <div v-if="bevelSelect && connectionType !== 'SemiFlush'" class="sidebar_block">
                      <img v-if="bevelSelect == 20" :src="imageSpecialBevel" style="width: 170px" crossOrigin="anonymous">
                      <img v-if="bevelSelect == 45" :src="imageStandardBevel" style="width: 170px" crossOrigin="anonymous">
                    </div>
                    <div v-if="profile.hasJfeDataManagerPermission && gradeSelect" style="margin-top: auto">
                      <hr v-if="customNotes" style="margin: 0 0 15px">
                      <div v-if="customNotes" style="text-align: left; line-height: 1.2">
                        <div class="columns is-vcentered is-mobile" style="margin-bottom: -5px;">
                          <div class="column has-text-weight-bold">
                            Notes:
                          </div>
                          <div class="column is-narrow">
                            <b-icon icon="pencil" type="is-grey" class="is-clickable hide-for-print margin-x-2" size="is-small" @click.native="addNotes" />
                            <b-icon icon="trash-alt" type="is-grey" class="is-clickable hide-for-print" size="is-small" @click.native="customNotes = null" />
                          </div>
                        </div>
                        <pre style="padding: 0; background: none; font-family: inherit; white-space: pre-wrap; overflow-wrap: break-word;">{{ customNotes }}</pre>
                      </div>
                      <b-button v-else type="is-primary" class="hide-for-print" data-html2canvas-ignore="true" @click="addNotes">
                        Add Notes
                      </b-button>
                    </div>
                    <!-- <div v-if="frictionSelect === 'Clr'" class="sidebar_block">
                      <img style="width: 200px" src="@/assets/images/datasheet/clear-run.png">
                    </div> -->
                  </div>
                  <div class="column">
                    <table v-if="weightSelect" class="table is-fullwidth is-bordered has-text-black">
                      <thead>
                        <th colspan="3" />
                        <th width="120" class="has-text-black">
                          {{ dataLanguages['imperial'][languageSelect] }}
                        </th>
                        <th width="120" class="has-text-black">
                          {{ dataLanguages['metric'][languageSelect] }}
                        </th>
                      </thead>
                      <tbody v-if="weightSelect">
                        <tr>
                          <td rowspan="8" class="identifier">
                            {{ dataLanguages['pipe'][languageSelect] }}
                          </td>
                          <td colspan="2">
                            {{ dataLanguages['pipe_body_wall'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="weight.pipeBodyWall" :key="weight.id" v-model="weight.pipeBodyWall" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ weight.pipeBodyWall }} in
                            </label-editable>
                          </td>
                          <td><span v-if="weight.pipeBodyWall">{{ inchmm(weight.pipeBodyWall) }} mm</span></td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            {{ dataLanguages['pipe_id'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="pipeID" :key="weight.id" v-model="pipeID" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ numberFormat(pipeID, 3) }} in
                              <b-tooltip v-if="showFormulas" type="is-dark" multilined size="is-large" position="is-left">
                                <b-icon icon="info-circle" size="is-small" />
                                <template v-slot:content>
                                  <span v-html="getInfoText('pipeId')" />
                                </template>
                              </b-tooltip>
                            </label-editable>
                          </td>
                          <td><span v-if="pipeID">{{ inchmm(pipeID, m_r) }} mm</span></td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            {{ dataLanguages['drift_diameter'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="driftDiameter" :key="weight.id" v-model="driftDiameter" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ numberFormat(driftDiameter, 3) }} in
                              <b-tooltip v-if="showFormulas" type="is-dark" multilined size="is-small" position="is-left">
                                <b-icon icon="info-circle" size="is-small" />
                                <template v-slot:content>
                                  <span v-html="getInfoText('driftDiameter')" />
                                </template>
                              </b-tooltip>
                            </label-editable>
                          </td>
                          <td><span v-if="driftDiameter">{{ inchmm(driftDiameter, m_r) }} mm</span></td>
                        </tr>
                        <!-- <tr v-show="weight.spDriftDiameter !== null"> -->
                        <tr v-show="hasSpDrift">
                          <td colspan="2">
                            {{ dataLanguages['special_drift_diameter'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="weight.spDriftDiameter" :key="weight.id" v-model="weight.spDriftDiameter" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ numberFormat(weight.spDriftDiameter, 3) }} in
                            </label-editable>
                          </td>
                          <td><span v-if="weight.spDriftDiameter">{{ inchmm(weight.spDriftDiameter, m_r) }} mm</span></td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            {{ dataLanguages['pipe_cross_section'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="pipeCrossSection" :key="weight.id" v-model="pipeCrossSection" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ numberFormat(pipeCrossSection, m_r) }} in2
                              <b-tooltip v-if="showFormulas" type="is-dark" multilined size="is-small" position="is-left">
                                <b-icon icon="info-circle" size="is-small" />
                                <template v-slot:content>
                                  <span v-html="getInfoText('pipeCrossSection')" />
                                </template>
                              </b-tooltip>
                            </label-editable>
                          </td>
                          <td><span v-if="pipeCrossSection">{{ astmRound(inchmm2(pipeCrossSection, m_r), 0 ) }} mm2</span></td>
                        </tr>
                        <tr v-if="gradeSelect">
                          <td colspan="2">
                            {{ dataLanguages['collapse_pressure'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="collapsePressure" :key="gradeSelect" v-model="collapsePressure" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ numberFormat(collapsePressure,0) }} psi
                              <b-tooltip v-if="showFormulas" type="is-dark" multilined size="is-large" position="is-left">
                                <b-icon icon="info-circle" size="is-small" />
                                <template v-slot:content>
                                  <span v-html="getInfoText('collapsePressure')" />
                                </template>
                              </b-tooltip>
                            </label-editable>
                            <b-dropdown v-if="!printingPdf && fieldsEditalbe && grade.name.includes('HC')" ref="hcdd" :can-close="false" :mobile-modal="false" aria-role="menu" trap-focus style="float: right">
                              <b-button
                                slot="trigger"
                                class="is-small is-dark is-outlined"
                                style="border-radius: 0; height: auto; padding: 1px 6px; margin-left: 5px;"
                                role="button">
                                HC
                              </b-button>

                              <b-dropdown-item aria-role="menu-item" :focusable="false" custom>
                                <b-field label="High Collapse">
                                  <b-input v-model="gradeHcValue" expanded />
                                </b-field>
                              </b-dropdown-item>

                              <b-dropdown-item custom class="has-text-right">
                                <b-button class="is-success" @click="saveHC">
                                  Save
                                </b-button>
                              </b-dropdown-item>
                            </b-dropdown>
                          </td>
                          <td><span v-if="collapsePressure">{{ psiMpa(collapsePressure, m_r) }} MPa</span></td>
                        </tr>
                        <tr v-if="gradeSelect">
                          <td colspan="2">
                            {{ dataLanguages['internal_yield_pressure'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="internalPressure" :key="gradeSelect" v-model="internalPressure" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ numberFormat(astmRound(internalPressure, -1), 0) }} psi
                              <b-tooltip v-if="showFormulas" type="is-dark" multilined size="is-large" position="is-left">
                                <b-icon icon="info-circle" size="is-small" />
                                <template v-slot:content>
                                  <span v-html="getInfoText('internalPressure')" />
                                </template>
                              </b-tooltip>
                            </label-editable>
                          </td>
                          <td><span v-if="internalPressure">{{ psiMpa(astmRound(internalPressure, -1), m_r) }} MPa</span></td>
                        </tr>
                        <tr v-if="gradeSelect">
                          <td colspan="2">
                            {{ dataLanguages['pipe_body_yield_strength'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="pipeBodyYieldStrength" :key="weight.id" v-model="pipeBodyYieldStrength" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ numberFormat(pipeBodyYieldStrength, 0) }} kip
                              <b-tooltip v-if="showFormulas" type="is-dark" multilined size="is-large" position="is-left">
                                <b-icon icon="info-circle" size="is-small" />
                                <template v-slot:content>
                                  <span v-html="getInfoText('pipeBodyYieldStrength')" />
                                </template>
                              </b-tooltip>
                            </label-editable>
                          </td>
                          <td><span v-if="pipeBodyYieldStrength">{{ numberFormat(kips(pipeBodyYieldStrength), 0) }} kN</span></td>
                        </tr>
                      </tbody>
                      <tbody v-if="gradeSelect">
                        <tr>
                          <td rowspan="3" class="identifier">
                            {{ dataLanguages['material'][languageSelect] }}
                          </td>
                          <td rowspan="2" class="middle">
                            Yield Strength
                          </td>
                          <td>Min</td>
                          <td>
                            <label-editable v-if="grade.yieldStrengthMin" :key="grade.id" v-model="grade.yieldStrengthMin" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ grade.yieldStrengthMin }} psi
                            </label-editable>
                          </td>
                          <td><span v-if="grade.yieldStrengthMin">{{ psiMpa(grade.yieldStrengthMin, 0) }} MPa</span></td>
                        </tr>
                        <tr>
                          <td>Max</td>
                          <td>
                            <label-editable v-if="grade.yieldStrengthMax" :key="grade.id" v-model="grade.yieldStrengthMax" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ grade.yieldStrengthMax }} psi
                            </label-editable>
                          </td>
                          <td><span v-if="grade.yieldStrengthMax">{{ psiMpa(grade.yieldStrengthMax, 0) }} MPa</span></td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            {{ dataLanguages['min_tensile_strength'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="grade.tensileStrengthMin" :key="grade.id" v-model="grade.tensileStrengthMin" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ grade.tensileStrengthMin }} psi
                            </label-editable>
                          </td>
                          <td><span v-if="grade.tensileStrengthMin">{{ psiMpa(grade.tensileStrengthMin, 0) }} MPa</span></td>
                        </tr>
                      </tbody>
                      <tbody v-if="couplingSelect">
                        <tr>
                          <td rowspan="6" class="identifier">
                            <span v-if="connection.type === 'SemiFlush'" class="is-uppercase">Connection Dimensions</span>
                            <span v-else>{{ dataLanguages['coupling'][languageSelect] }}</span>
                            <div v-if="weightSelect && weight.sd" style="margin-top: 15px; font-size: 70%; font-weight: normal; font-style: italic">
                              ** SD is an identification code for distinguishing interchangeability.
                            </div>
                          </td>
                          <td rowspan="2" class="middle">
                            <span v-if="connection.type === 'SemiFlush'">Box Diameter</span>
                            <span v-else>Coupling Diameter</span>
                          </td>
                          <td>OD/Turning Dia</td>
                          <td>
                            <span v-if="couplingSelect">
                              <label-editable :key="couplingSelect" v-model="weight['couplings'][weight['couplings'].indexOf(couplingById[couplingSelect])]['cplgOd']" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                                {{ couplingById[couplingSelect].cplgOd }} in
                              </label-editable>
                            </span>
                          </td>
                          <td v-if="couplingSelect">
                            <span>{{ inchmm(couplingById[couplingSelect].cplgOd) }} mm</span>
                          </td>
                        </tr>
                        <tr>
                          <td>ID</td>
                          <td>
                            <label-editable v-if="weight.couplingId" :key="couplingSelect" v-model="weight.couplingId" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ weight.couplingId }} in
                            </label-editable>
                          </td>
                          <td><span v-if="weight.couplingId">{{ inchmm(weight.couplingId) }} mm</span></td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            {{ dataLanguages['tensile_efficency'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="tensileEfficiencyTe" :key="gradeSelect" v-model="tensileEfficiencyTe" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ tensileEfficiencyTe }}%
                            </label-editable>
                          </td>
                          <td><span v-if="tensileEfficiencyTe">{{ tensileEfficiencyTe }}%</span></td>
                        </tr>
                        <tr v-if="connectionType !== 'SemiFlush'">
                          <td colspan="2">
                            {{ dataLanguages['coupling_length'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="weight.couplingLength" :key="couplingSelect" v-model="weight.couplingLength" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ weight.couplingLength }} in
                            </label-editable>
                          </td>
                          <td><span v-if="weight.couplingLength">{{ inchmm(weight.couplingLength) }} mm</span></td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            {{ dataLanguages['make_up_loss_length'][languageSelect] }}
                            <span v-if="weightSelect && weight.sd" class="margin-x-3">
                              SD: {{ weight.sd }} **
                            </span>
                          </td>
                          <td>
                            <label-editable v-if="weight.makeupLoss" :key="couplingSelect" v-model="weight.makeupLoss" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ weight.makeupLoss }} in
                            </label-editable>
                          </td>
                          <td><span v-if="weight.makeupLoss">{{ inchmm(weight.makeupLoss) }} mm</span></td>
                        </tr>
                        <tr v-if="maxBFLoad10 && bevelSelect && connectionType !== 'SemiFlush'">
                          <td colspan="2">
                            {{ dataLanguages['bearing_face_load'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="maxBFLoad10" :key="couplingSelect" v-model="maxBFLoad10" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ numberFormat(astmRound(maxBFLoad10, m_r ), 0) }} kip
                              <b-tooltip v-if="showFormulas" type="is-dark" multilined size="is-large" position="is-left">
                                <b-icon icon="info-circle" size="is-small" />
                                <template v-slot:content>
                                  <span v-html="getInfoText('maxBFLoad10')" />
                                </template>
                              </b-tooltip>
                            </label-editable>
                          </td>
                          <td><span v-if="maxBFLoad10">{{ numberFormat(kips(maxBFLoad10), m_r) }} kN</span></td>
                        </tr>
                      </tbody>
                      <tbody v-if="couplingSelect">
                        <tr>
                          <td rowspan="6" class="identifier">
                            {{ dataLanguages['connection_performance'][languageSelect] }}
                          </td>
                          <td colspan="2">
                            {{ dataLanguages['collapse_pressure'][languageSelect] }}
                          </td>
                          <td>
                            <span v-if="collapsePressure">
                              {{ numberFormat(collapsePressure,0) }} psi
                              <b-tooltip v-if="showFormulas" type="is-dark" multilined size="is-large" position="is-left">
                                <b-icon icon="info-circle" size="is-small" />
                                <template v-slot:content>
                                  <span v-html="getInfoText('collapsePressure')" />
                                </template>
                              </b-tooltip>
                            </span>
                          </td>
                          <td><span v-if="collapsePressure">{{ psiMpa(collapsePressure, m_r) }} MPa</span></td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            {{ dataLanguages['internal_yield_pressure'][languageSelect] }}
                          </td>
                          <td>
                            <span v-if="internalPressure">
                              {{ numberFormat(astmRound(internalPressure, -1), 0) }} psi
                              <b-tooltip v-if="showFormulas" type="is-dark" multilined size="is-large" position="is-left">
                                <b-icon icon="info-circle" size="is-small" />
                                <template v-slot:content>
                                  <span v-html="getInfoText('internalPressure')" />
                                </template>
                              </b-tooltip>
                            </span>
                          </td>
                          <td><span v-if="internalPressure">{{ psiMpa(astmRound(internalPressure, -1), m_r) }} MPa</span></td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            {{ dataLanguages['joint_strength'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="jointStrength" :key="couplingSelect" v-model="jointStrength" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ numberFormat(jointStrength, 0) }} kip
                              <b-tooltip v-if="showFormulas" type="is-dark" multilined size="is-large" position="is-left">
                                <b-icon icon="info-circle" size="is-small" />
                                <template v-slot:content>
                                  <span v-html="getInfoText('jointStrength')" />
                                </template>
                              </b-tooltip>
                            </label-editable>
                          </td>
                          <td><span v-if="jointStrength">{{ numberFormat(kips(jointStrength), 0) }} kN</span></td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            {{ dataLanguages['joint_tensile_efficiency'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="tensileEfficiencyJteo" :key="gradeSelect" v-model="tensileEfficiencyJteo" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ tensileEfficiencyJteo }}%
                            </label-editable>
                          </td>
                          <td><span v-if="tensileEfficiencyJteo">{{ tensileEfficiencyJteo }}%</span></td>
                        </tr>
                        <tr v-if="compressionRating">
                          <td colspan="2">
                            {{ dataLanguages['compression_rating'][languageSelect] }}
                          </td>
                          <td>
                            <label-editable v-if="compressionRating" :key="couplingSelect" v-model="compressionRating" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ numberFormat(compressionRating, 0) }} kip
                              <b-tooltip v-if="showFormulas" type="is-dark" multilined size="is-large" position="is-left">
                                <b-icon icon="info-circle" size="is-small" />
                                <template v-slot:content>
                                  <span v-html="getInfoText('compressionRating')" />
                                </template>
                              </b-tooltip>
                            </label-editable>
                          </td>
                          <td><span v-if="compressionRating">{{ numberFormat(kips(compressionRating), 0) }} kN</span></td>
                        </tr>
                        <tr v-if="weight.bendingModulus">
                          <td colspan="2">
                            Max Bending with sealability
                          </td>
                          <td>
                            <label-editable v-if="maxBending" :key="couplingSelect" v-model="maxBending" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              {{ numberFormat( maxBending, 0) }}°/100ft
                              <b-tooltip v-if="showFormulas" type="is-dark" multilined size="is-large" position="is-left">
                                <b-icon icon="info-circle" size="is-small" />
                                <template v-slot:content>
                                  <span v-html="getInfoText('maxBending')" />
                                </template>
                              </b-tooltip>
                            </label-editable>
                          </td>
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <hr v-if="frictionSelect" class="margin-y-0">
              <div v-if="frictionSelect" class="padding-xy-3">
                <div class="columns">
                  <div class="sidebar column is-narrow">
                    <div class="sidebar_block" style="padding: 0">
                      <div class="header">
                        Graph Signature
                      </div>
                      <img v-if="imageGraph" :src="imageGraph.screenshotUrl" style="width: 170px" crossOrigin="anonymous">
                    </div>
                  </div>
                  <div class="column">
                    <table class="table is-fullwidth is-bordered">
                      <tbody>
                        <tr>
                          <td rowspan="6" class="identifier">
                            <div>
                              {{ dataLanguages['field_make_up_torque'][languageSelect] }}
                            </div>
                            <div style="margin-top: 20px; font-size: 12px; font-weight: normal;">
                              {{ frictionFactor() }}
                            </div>
                            <!-- <div v-if="footNotes.length > 0" class="has-text-weight-normal is-size-7" style="margin-top: 15px">
                              <div v-for="note in footNotes" :key="note.index">
                                [*{{ note.index }}] {{ note.descrption }}
                              </div>
                            </div> -->
                          </td>
                          <td rowspan="3" class="middle">
                            Torque
                          </td>
                          <td>Min</td>
                          <td width="120">
                            <label-editable :key="frictionSelect" v-model="torqueFtMin" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              <span v-if="typeof torqueFtMin == 'number'">
                                {{ numberFormat(torqueFtMin, 0, true) }} ft-lb
                              </span>
                              <span v-else>
                                TBD
                              </span>
                            </label-editable>
                          </td>
                          <td width="120">
                            <span v-if="typeof torqueFtMin == 'number'">{{ numberFormat(astmRound(lbkg(torqueFtMin), m_r ), 0, true) }} Nm</span>
                            <span v-else>TBD</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Opt</td>
                          <td>
                            <label-editable :key="frictionSelect" v-model="torqueFtOpt" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              <span v-if="typeof torqueFtOpt == 'number'">
                                {{ numberFormat(torqueFtOpt, 0, true) }} ft-lb
                              </span>
                              <span v-else>TBD</span>
                            </label-editable>
                          </td>
                          <td>
                            <span v-if="typeof torqueFtOpt == 'number'">{{ numberFormat(astmRound(lbkg(torqueFtOpt), m_r ), 0, true) }} Nm</span>
                            <span v-else>TBD</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Max</td>
                          <td>
                            <label-editable :key="frictionSelect" v-model="torqueFtMax" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              <span v-if="typeof torqueFtMax == 'number'">
                                {{ numberFormat(torqueFtMax, 0, true) }} ft-lb
                              </span>
                              <span v-else>TBD</span>
                            </label-editable>
                          </td>
                          <td>
                            <span v-if="typeof torqueFtMax == 'number'">{{ numberFormat(astmRound(lbkg(torqueFtMax), m_r ), 0, true) }} Nm</span>
                            <span v-else>TBD</span>
                          </td>
                        </tr>
                        <tr>
                          <td rowspan="2" class="middle">
                            Shoulder
                          </td>
                          <td>Min</td>
                          <td>
                            <label-editable :key="frictionSelect" v-model="torqueStMin" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              <span v-if="typeof torqueStMin == 'number'">
                                {{ numberFormat(torqueStMin, 0, true) }} ft-lb
                              </span>
                              <span v-else>TBD</span>
                            </label-editable>
                          </td>
                          <td>
                            <span v-if="typeof torqueStMin == 'number'">{{ numberFormat(astmRound(lbkg(torqueStMin), m_r ), 0, true) }} Nm</span>
                            <span v-else>TBD</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Max</td>
                          <td>
                            <label-editable :key="frictionSelect" v-model="torqueStMax" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              <span v-if="typeof torqueStMax == 'number'">
                                {{ numberFormat(torqueStMax, 0, true) }} ft-lb
                              </span>
                              <span v-else>TBD</span>
                            </label-editable>
                          </td>
                          <td>
                            <span v-if="typeof torqueStMax == 'number'">{{ numberFormat(astmRound(lbkg(torqueStMax), m_r ), 0, true) }} Nm</span>
                            <span v-else>TBD</span>
                          </td>
                        </tr>
                        <tr v-show="showMts">
                          <td colspan="2">
                            Max Torque with Sealability
                          </td>
                          <td>
                            <label-editable :key="frictionSelect" v-model="torqueSealMax" :sign="'*'" :disabled="!fieldsEditalbe" @has-updated="hasSomeEditedFields">
                              <span v-if="typeof torqueSealMax == 'number'">
                                {{ numberFormat(torqueSealMax, 0, true) }} ft-lb
                              </span>
                              <span v-else>TBD</span>
                            </label-editable>
                          </td>
                          <td>
                            <span v-if="typeof torqueSealMax == 'number'">{{ numberFormat(astmRound(lbkg(torqueSealMax), m_r ), 0, true) }} Nm</span>
                            <span v-else>TBD</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <hr v-if="finishedSelection" class="margin-y-0">
              <div v-if="finishedSelection" class="padding-xy-3">
                <!-- <strong>{{dataLanguages['legal_notice'][languageSelect]}}</strong> -->
                <div class="footer_notes">
                  <p style="margin-bottom: 5px">
                    <span v-html="dataLanguages['latest_information'][languageSelect]" />
                  </p>
                  <p>
                    <span v-html="dataLanguages['legal_notice_txt'][languageSelect]" />
                  </p>
                  <p v-if="hasEdited">
                    * This has been edited by {{ profile.username || 'guest' }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Moment from 'moment'
import AdminApi from '@/apis/AdminApi'
import LabelEditable from '@/components/fields/LabelEditable'
import DatasheetNotes from '@/components/modals/DatasheetNotes'
// import StripingDiagram from '@/components/misc/StripingDiagram'
import ModelViewer from '@/components/misc/ModelViewer'
import Vue2Filters from 'vue2-filters'

export default {
  name: 'DatasheetGenerator',
  components: {
    LabelEditable,
    // StripingDiagram,
    ModelViewer
  },
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      loading: true,
      languageSelect: 'english',
      connectionSelect: null,
      sizeSelect: null,
      weightSelect: null,
      gradeSelect: null,
      frictionSelect: null,
      frictionFactorType: null,
      couplingSelect: null,
      bevelSelect: null,
      sdInfo: null,
      noteKeyTorque: null,
      hasEdited: false,
      fieldsEditalbe: false,
      gradeHcValue: null,
      gradeHc: null,
      customNotes: null,
      showFormulas: false,
      printingPdf: false,
      savedVars: {
        pipeId: null,
        driftDiameter: null,
        pipeCrossSection: null,
        internalPressure: null,
        pipeBodyYieldStrength: null,
        maxBFLoad10: null,
        collapsePressure: null,
        torqueFtMin: null,
        torqueFtOpt: null,
        torqueFtMax: null,
        torqueStMin: null,
        torqueStMax: null,
        torqueSealMax: null,
        jointStrength: null,
        compressionRating: null,
        maxBending: null,
        tensileEfficiencyJteo: null,
        tensileEfficiencyTe: null
      },
      connectionVector: [],
      gradeVector: [],
      hiddenGrades: [],
      showSpDrifts: [],
      gradeStripes: {
        tncField: 'white',
        tncCouplingStripe1: 'none',
        tncCouplingStripe2: 'none',
        tncCouplingStripe3: 'none',
        tncTubeStripe1: 'none',
        tncTubeStripe2: 'none',
        tncTubeStripe3: 'none',
        plainendStripe1: 'none',
        plainendStripe2: 'none',
        plainendStripe3: 'none',
        plainendStripe4: 'none',
        semiflushStripe1: 'none',
        semiflushStripe2: 'none',
        semiflushStripe3: 'none',
        semiflushStripe4: 'none'
      }
    }
  },
  computed: {
    hasPermissionToShowFormulas () {
      if (this.profile.hasJfeAdminPermission || this.profile.hasJfeDataManagerPermission) {
        return true
      } else {
        return false
      }
    },
    specialCoupling () {
      return this.couplingSelect ? !!this.couplingById[this.couplingSelect].specialClearance : false
    },
    connectionType () {
      let type = null
      for (const c of this.connectionVector) {
        if (c.id === this.connectionSelect) {
          type = c.type
        }
      }
      return type
    },
    profile () {
      return this.$store.state.profile
    },
    connections () {
      return this.connectionVector
      // return this.$store.state.connections.filter(c => c.datasheetAvailability)
    },
    couplingData () {
      return this.orderCouplilngVetor(this.weight.couplings.filter(c => c.datasheetsEnabled))
    },
    dataLanguages () {
      const mapped = {}

      this.$store.state.datasheetLanguages.forEach(lang => {
        mapped[lang.key] = lang
      })
      return mapped
    },
    dateHeader () {
      return Moment().format('MMM D, YYYY')
    },
    connection () {
      return this.connections.find(c => c.id === this.connectionSelect)
    },
    size () {
      return this.sizesFiltered.find(s => s.id === this.sizeSelect)
    },
    sizesFiltered () {
      return this.connections.find(c => c.id === this.connectionSelect).sizes.filter(s => !s.deleted)
    },
    weight () {
      return this.weightsFiltered.find(w => w.id === this.weightSelect)
    },
    weightsFiltered () {
      return this.sizesFiltered.find(s => s.id === this.sizeSelect).weights.filter(w => !w.deleted)
    },
    maxBending: {
      get () {
        if (this.savedVars.maxBending) {
          return this.savedVars.maxBending
        } else {
          if (this.connection.type === 'PlanEnd') {
            return null
          } else {
            console.log('maxBending starts')
            console.log('this.weight.bendingModulus=' + this.weight.bendingModulus)
            console.log('this.tensileEfficiencyJteo=' + this.tensileEfficiencyJteo)
            console.log('this.grade.compressiveStrength=' + this.grade.compressiveStrength)
            console.log('maxBending ends')
            return this.weight.bendingModulus * (this.tensileEfficiencyJteo / 100) * (this.grade.compressiveStrength / 100)
          }
        }
      },
      set (val) {
        this.savedVars.maxBending = val
      }
    },
    tensileEfficiencyJteo: {
      get () {
        if (this.savedVars.tensileEfficiencyJteo) {
          return this.savedVars.tensileEfficiencyJteo
        } else {
          return this.tensileEfficiency.jte
        }
      },
      set (val) {
        this.savedVars.tensileEfficiencyJteo = val
      }
    },
    tensileEfficiencyTe: {
      get () {
        if (this.savedVars.tensileEfficiencyTe) {
          return this.savedVars.tensileEfficiencyTe
        } else {
          return this.tensileEfficiency.te
        }
      },
      set (val) {
        this.savedVars.tensileEfficiencyTe = val
      }
    },
    gradesFiltered () {
      if (this.profile.hasJfeAdminPermission || this.profile.hasJfeDataManagerPermission) {
        return this.gradeVector
      } else {
        return this.gradeVector.filter(g => (
          !this.hiddenGrades.includes(g.id)
        )).filter(grade => grade.public)
        // return this.gradeVector.filter(grade => grade.public)
      }
      // return this.$store.state.connectionGrades.filter(g => g.id !== 0)
    },
    grade () {
      return this.gradesFiltered.find(g => g.id === this.gradeSelect)
    },
    footNotes () {
      return this.footNotesMethod()
    },
    driftDiameter: {
      get () {
        if (this.savedVars.driftDiameter) {
          return this.savedVars.driftDiameter
        } else {
          return this.driftDiaCasing(this.size.size, this.weight.pipeBodyWall)
        }
      },
      set (val) {
        this.savedVars.driftDiameter = val
      }
    },
    pipeCrossSection: {
      get () {
        if (this.savedVars.pipeCrossSection) {
          return this.savedVars.pipeCrossSection
        } else {
          // CROSS SECTION
          const pID = this.size.size - (this.weight.pipeBodyWall * 2)
          const pOD = this.size.size
          // let area_OD = Math.PI * (Math.pow(pOD, 2))
          // let area_ID = Math.PI * (Math.pow(pID, 2))

          return Math.PI * (Math.pow((pOD / 2), 2) - Math.pow((pID / 2), 2))
        }
      },
      set (val) {
        this.savedVars.pipeCrossSection = val
      }
    },
    collapsePressure: {
      get () {
        if (this.savedVars.collapsePressure) {
          return this.savedVars.collapsePressure
        } else {
          return this.gradeHcValue ? this.gradeHcValue : this.calcCollapse(this.size.size, this.weight.pipeBodyWall, this.grade.yieldStrengthMin)
        }
      },
      set (val) {
        this.savedVars.collapsePressure = val
      }
    },
    internalPressure: {
      get () {
        if (this.savedVars.internalPressure) {
          return this.savedVars.internalPressure
        } else {
          return this.calcInternalPressure(this.size.size, this.weight.pipeBodyWall, this.grade.yieldStrengthMin, this.grade.guaranteedWall)
        }
      },
      set (val) {
        this.savedVars.internalPressure = val
      }
    },
    pipeBodyYieldStrength: {
      get () {
        if (this.savedVars.pipeBodyYieldStrength) {
          return this.savedVars.pipeBodyYieldStrength
        } else {
          return this.calcPBYS(this.size.size, this.weight.pipeBodyWall, this.grade.yieldStrengthMin)
        }
      },
      set (val) {
        this.savedVars.pipeBodyYieldStrength = val
      }
    },
    imageConnection () {
      return this.connections.find(c => c.id === this.connectionSelect).images.find(i => i.name === 'Logo')
    },
    imageSpecialBevel () {
      // return this.connections.find(c => c.id === this.connectionSelect).images.find(i => i.name === 'Special Bevel')
      return require('@/assets/images/datasheet/_bevel/special-bevel-1.png')
    },
    imageStandardBevel () {
      // return this.connections.find(c => c.id === this.connectionSelect).images.find(i => i.name === 'Standard Bevel')
      return require('@/assets/images/datasheet/_bevel/standard-bevel-1.png')
    },
    imageGraph () {
      return this.connections.find(c => c.id === this.connectionSelect).images.find(i => i.name === 'Graph')
    },
    tensileEfficiency () {
      var tensileEfficiency = {}
      // NEW
      tensileEfficiency.jte = this.couplingById[this.couplingSelect].jte
      tensileEfficiency.jteo = this.couplingById[this.couplingSelect].jte
      tensileEfficiency.te = this.couplingById[this.couplingSelect].te

      if (this.couplingById[this.couplingSelect].jte > 100) {
        tensileEfficiency.jteo = 100
      }

      return tensileEfficiency
    },
    maxBFLoad10: {
      get () {
        if (this.savedVars.maxBFLoad10) {
          return this.savedVars.maxBFLoad10
        } else {
          let bfArea = 0
          switch (parseInt(this.bevelSelect)) {
            case 20:
              bfArea = this.couplingById[this.couplingSelect].bfAr20
              break
            case 45:
              bfArea = this.couplingById[this.couplingSelect].bfAr45
              break
            default:
              bfArea = 0
              break
          }
          console.log('maxBFLoad10 starts')
          console.log('this.grade.yieldStrengthMin=' + this.grade.yieldStrengthMin)
          console.log('bfArea=' + bfArea)
          console.log('this.grade.compressiveStrength=' + this.grade.compressiveStrength)
          console.log('maxBFLoad10 ends')
          return this.bearingFaceLoad(this.grade.yieldStrengthMin, bfArea, this.grade.compressiveStrength)
        }
      },
      set (val) {
        this.savedVars.maxBFLoad10 = val
      }
    },
    torqueFtMin: {
      get () {
        if (this.savedVars.torqueFtMin) {
          return this.savedVars.torqueFtMin
        } else {
          return this.torque('Ft', 'Min')
        }
      },
      set (val) {
        this.savedVars.torqueFtMin = val
      }
    },
    torqueFtOpt: {
      get () {
        if (this.savedVars.torqueFtOpt) {
          return this.savedVars.torqueFtOpt
        } else {
          return this.torque('Ft', 'Opt')
        }
      },
      set (val) {
        this.savedVars.torqueFtOpt = val
      }
    },
    torqueFtMax: {
      get () {
        if (this.savedVars.torqueFtMax) {
          return this.savedVars.torqueFtMax
        } else {
          return this.torque('Ft', 'Max')
        }
      },
      set (val) {
        this.savedVars.torqueFtMax = val
      }
    },
    torqueStMin: {
      get () {
        if (this.savedVars.torqueStMin) {
          return this.savedVars.torqueStMin
        } else {
          return this.torque('St', 'Min')
        }
      },
      set (val) {
        this.savedVars.torqueStMin = val
      }
    },
    torqueStMax: {
      get () {
        if (this.savedVars.torqueStMax) {
          return this.savedVars.torqueStMax
        } else {
          return this.torque('St', 'Max')
        }
      },
      set (val) {
        this.savedVars.torqueStMax = val
      }
    },
    torqueSealMax: {
      get () {
        if (this.savedVars.torqueSealMax) {
          return this.savedVars.torqueSealMax
        } else {
          return this.torque('Mts', '')
        }
      },
      set (val) {
        this.savedVars.torqueSealMax = val
      }
    },
    jointStrength: {
      get () {
        if (this.savedVars.jointStrength) {
          return this.savedVars.jointStrength
        } else {
          return this.tensileEfficiencyJteo ? this.calcJointStrengthSub(this.size.size, this.weight.pipeBodyWall, this.grade.yieldStrengthMin, this.tensileEfficiencyJteo) : null
        }
      },
      set (val) {
        this.savedVars.jointStrength = val
      }
    },
    compressionRating: {
      get () {
        if (this.savedVars.compressionRating) {
          return this.savedVars.compressionRating
        } else {
          var jointX = 0
          console.log('compressionRating starts')

          if (!!this.weight.compressionRating && !!this.grade.compressiveStrength) {
            console.log('this.grade.compressiveStrength=' + JSON.stringify(this.grade.compressiveStrength))
            console.log('this.weight.compressionRating=' + JSON.stringify(this.weight.compressionRating))
            jointX = (this.weight.compressionRating / 100) * this.grade.compressiveStrength
          }
          console.log('jointX=' + JSON.stringify(jointX))
          console.log('jointStrength=' + JSON.stringify(this.jointStrength))
          console.log('compressionRating ends')
          return (this.jointStrength * (jointX / 100))
        }
      },
      set (val) {
        this.savedVars.compressionRating = val
      }
    },
    couplingLabel () {
      return this.couplingById[this.couplingSelect] ? this.couplingById[this.couplingSelect].name : null
    },
    gradePic () {
      if (this.grade.screenshotUrl) {
        return this.grade.screenshotUrl
      } else {
        return require('@/assets/images/datasheet/grade_pics/none.jpg')
      }
    },
    pipeID: {
      get () {
        if (this.savedVars.pipeId) {
          return this.savedVars.pipeId
        } else {
          return (this.size.size - (this.weight.pipeBodyWall * 2))
        }
      },
      set (val) {
        this.savedVars.pipeId = val
      }
    },
    hasClearRun () {
      const chrome = this.grade.chrome ? 'Chrome' : 'Carbon'
      let gradeKsi = null
      if (this.size && Number(this.size.size) <= 4.5) {
        gradeKsi = this.grade.gradeKsiLess
      } else {
        gradeKsi = this.grade.gradeKsiGreater
      }

      if (this.weight['clr' + chrome + 'Ksi' + gradeKsi + 'FtMin']) {
        return true
      }
      if (this.weight['clr' + chrome + 'Ksi' + gradeKsi + 'FtMax']) {
        return true
      }
      if (this.weight['clr' + chrome + 'Ksi' + gradeKsi + 'FtOpt']) {
        return true
      }
      if (this.weight['clr' + chrome + 'Ksi' + gradeKsi + 'StMin']) {
        return true
      }
      if (this.weight['clr' + chrome + 'Ksi' + gradeKsi + 'StMax']) {
        return true
      }
      if (this.weight['clr' + chrome + 'Ksi' + gradeKsi + 'Mts']) {
        return true
      }

      return false
    },

    // availableGradeObject () {
    //   return this.gradesFiltered.filter(g => (
    //     !this.hiddenGrades.includes(g.id)
    //   ))
    // }

    hasSpDrift () {
      if (this.gradeSelect) {
        return !!(this.showSpDrifts.includes(this.gradeSelect) && this.weight.spDriftDiameter !== null)
      } else {
        return false
      }
    },

    couplingById () {
      const mapped = {}
      this.weight.couplings.forEach(c => {
        mapped[c.id] = c
      })

      return mapped
    },

    finishedSelection () {
      if (this.couplingSelect && this.connectionType === 'SemiFlush') return true
      if (this.gradeSelect && this.connectionType === 'PlainEnd') return true
      if (this.bevelSelect) return true
      return false
    },

    showMts () {
      if (this.size && Number(this.size.size) <= 4.5) {
        if (this.grade.gradeKsiLessHasMst) {
          if (this.torqueSealMax) {
            return true
          }
        }
      } else {
        if (this.grade.gradeKsiGreaterHasMst) {
          if (this.torqueSealMax) {
            return true
          }
        }
      }
      return false
    }
  },
  async mounted () {
    this.$store.commit('setApp', 'datasheet')
    await this.$store.commit('setTitle', 'Datasheet Generator')
    await this.$store.dispatch('refreshConnections')
    this.loadConnections()

    await this.$store.dispatch('refreshConnectionGrades')
    this.loadGrades()

    await this.$store.dispatch('refreshDatasheetLanguages')
    if (this.profile.type === 'jfe' && this.profile.hasJfeDataManagerPermission) {
      this.fieldsEditalbe = true
    }
    this.loading = false
  },
  methods: {
    hasSomeEditedFields (hasSome) {
      if (hasSome) {
        this.hasEdited = true
      }
    },
    addNotes () {
      this.$buefy.modal.open({
        parent: this,
        component: DatasheetNotes,
        hasModalCard: true,
        props: {
          notes: this.customNotes
        },
        events: {
          save: value => {
            this.customNotes = value
          }
        }
      })
    },
    resetSheet () {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to reset?',
        confirmText: 'Reset Datasheet',
        type: 'is-danger',
        onConfirm: async () => {
          this.connectionSelect = null
          this.changeSheet('default')
          await this.$store.dispatch('refreshConnectionGrades')
        }
      })
    },
    async loadConnections () {
      // https://dev.to/rsschouwenaar/how-to-make-a-real-copy-of-a-javascript-array-with-objects-without-a-reference-5md
      this.connectionVector = JSON.parse(JSON.stringify(this.$store.state.connections.filter(c => c.datasheetAvailability)))
    },
    async loadGrades () {
      // await this.$store.dispatch('refreshConnectionGrades')
      this.gradeVector = JSON.parse(JSON.stringify(this.$store.state.connectionGrades.filter(g => g.id !== 0)))
    },
    async changeSheet (field) {
      switch (field) {
        case 'size':
          this.weightSelect = null
          this.gradeSelect = null
          this.frictionSelect = null
          this.couplingSelect = null
          this.bevelSelect = null
          this.customNotes = null
          // this.savedVars.maxBFLoad10 = null
          break
        case 'weight':
          // await this.$store.dispatch('refreshConnections')
          this.loadConnections()
          this.gradeSelect = null
          this.frictionSelect = null
          this.couplingSelect = null
          this.bevelSelect = null
          this.customNotes = null
          // Computed vars
          this.savedVars.pipeId = null
          this.savedVars.driftDiameter = null
          this.savedVars.pipeCrossSection = null
          this.savedVars.tensileEfficiencyJteo = null
          this.savedVars.tensileEfficiencyTe = null

          const res = await AdminApi.getGradeVisibiltyColumn(this.weightSelect)
          let hiddenGrades = []
          if (res.hiddenGrades) {
            hiddenGrades = res.hiddenGrades.split(',')
          }
          for (let i = 0; i < hiddenGrades.length; i++) {
            // convert char nums into ints
            hiddenGrades[i] = parseInt(hiddenGrades[i])
          }
          this.hiddenGrades = hiddenGrades

          const resSpDrifts = await AdminApi.getGradeSpDriftVisibiltyColumn(this.weightSelect)
          let showSpDrifts = []

          if (resSpDrifts.showSpDrifts) {
            showSpDrifts = resSpDrifts.showSpDrifts.split(',')
          }

          for (let i = 0; i < showSpDrifts.length; i++) {
            // convert char nums into ints
            showSpDrifts[i] = parseInt(showSpDrifts[i])
          }
          this.showSpDrifts = showSpDrifts
          break
        case 'grade':
          this.loading = true
          this.loadConnections()
          this.loadGrades()
          const gradeHc = await AdminApi.getConnectionGradeHC(this.connection.id, this.size.id, this.weight.id, this.grade.id)

          if (gradeHc) {
            this.gradeHc = gradeHc
            this.gradeHcValue = gradeHc.hc
          } else {
            this.gradeHc = null
            this.gradeHcValue = null
          }
          this.loading = false

          this.frictionSelect = null
          this.couplingSelect = null
          this.bevelSelect = null

          // Computed vars
          this.savedVars.collapsePressure = null
          this.savedVars.internalPressure = null
          this.savedVars.pipeBodyYieldStrength = null
          this.savedVars.maxBending = null
          this.savedVars.tensileEfficiencyJteo = null
          this.savedVars.tensileEfficiencyTe = null
          this.customNotes = null

          // get gradeStripes for striping diagram
          this.gradeStripes = await AdminApi.getGradeColorsById(this.gradeSelect)

          break
        case 'friction':
          this.savedVars.torqueFtMin = null
          this.savedVars.torqueFtOpt = null
          this.savedVars.torqueFtMax = null
          this.savedVars.torqueStMin = null
          this.savedVars.torqueStMax = null
          this.savedVars.torqueSealMax = null
          if (this.connection.type === 'SemiFlush') {
            const coupling = this.couplingData.find(c => c.name.toLowerCase() === 'std')
            if (coupling) {
              this.couplingSelect = coupling.id
            }
          }
          break
        case 'coupling':
          this.loadConnections()
          this.savedVars.maxBFLoad10 = null
          this.savedVars.jointStrength = null
          this.savedVars.compressionRating = null
          // Joint Strength
          // Joint Tensile Efficiency
          // Compression Rating
          break
        default:
          this.sizeSelect = null
          this.weightSelect = null
          this.gradeSelect = null
          this.frictionSelect = null
          this.couplingSelect = null
          this.bevelSelect = null
          this.customNotes = null
      }
    },
    poundToKg (value) {
      value = value / 2.205
      return parseFloat(value).toFixed(2)
    },
    weightHeader (value) {
      return value * 1.48816394
    },
    printPdf () {
      this.printingPdf = true
      setTimeout(() => {
        window.print()
        this.printingPdf = false
        // window.onafterprint = function () this.printingPdf = false
      }, 500)
    },
    createPDF (quality = 2) {
      this.printingPdf = true
      this.$scrollTo('body', 200)
      const filename = this.connection.name
      setTimeout(() => {
        html2canvas(document.querySelector('#pdf_wrapper'), {
          scale: quality,
          useCORS: true
        }).then(canvas => {
          const imgData = canvas.toDataURL('image/png')
          // eslint-disable-next-line
          const pdf = new jsPDF('portrait', 'in', 'letter')
          const imgProps = pdf.getImageProperties(imgData)
          const pdfWidth = pdf.internal.pageSize.getWidth()
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST')
          pdf.save(filename + '_datasheet.pdf', { returnPromise: true }).then()
          this.printingPdf = false
        })
      }, 300)
    },
    driftDiaCasing (size, wt) {
      // wt = Wall_Thickness = pipe_body_wall
      var id = size - 2 * wt
      if (size <= 2.875) {
        return id - 3 / 32
      } else if (size < 9.625) {
        return id - 1 / 8
      } else if (size <= 13.375) {
        return id - 5 / 32
      } else {
        return id - 3 / 16
      }
    },
    calcCollapse (size, wt, ys) {
      // wt = Wall_Thickness = pipe_body_wall
      // ys = YIELD_STRENGTH_MIN
      if (ys <= 24000) {
        return -1
      } else {
        const A = 2.8762 + 0.0000010679 * ys + 0.000000000021301 * Math.pow(ys, 2) - 0.000000000000000053132 * Math.pow(ys, 3)
        const B = 0.026233 + 0.00000050609 * ys
        const C = -465.93 + 0.030867 * ys - 0.000000010483 * Math.pow(ys, 2) + 0.000000000000036989 * Math.pow(ys, 3)
        const K = B / A
        const KKK = 3 * K / (2 + K)
        const F = 46950000 * Math.pow(KKK, 3) / (ys * ((KKK - K) * Math.pow((1 - KKK), 2)))
        const G = F * K
        const DTyp = (Math.pow((Math.pow((A - 2), 2) + 8 * (B + C / ys)), 0.5) + A - 2) / (2 * (B + C / ys))
        const DTpt = (ys * (A - F)) / (C + ys * (B - G))
        const DTte = (2 + B / A) / (3 * B / A)
        const DT = size / wt
        var rCalcCollapse
        if (DT <= DTyp) {
          rCalcCollapse = 2 * ys * (DT - 1) / Math.pow(DT, 2)
        } else if (DT <= DTpt) {
          rCalcCollapse = ys * (A / DT - B) - C
        } else if (DT <= DTte) {
          rCalcCollapse = ys * (F / DT - G)
        } else {
          rCalcCollapse = 46950000 / (DT * Math.pow((DT - 1), 2))
        }
        rCalcCollapse = (this.astmRound(rCalcCollapse / 10) * 10)
      }
      return rCalcCollapse
    },
    calcInternalPressure (size, wt, ys, guaranteedWall) {
      const MIN_WT_TOLERANCE = guaranteedWall
      return 2 * ys * (MIN_WT_TOLERANCE * wt) / size
    },
    calcPBYS (size, wt, ys) {
      // Pipe Body Yield Strength
      const pipeId = size - (2 * wt)
      const pipeArea = (Math.PI / 4) * (Math.pow(size, 2) - Math.pow(pipeId, 2))
      var calcPBYS = ys / 1000 * pipeArea
      // calcPBYS = this.astmRound(calcPBYS, 0)
      return calcPBYS
    },
    bearingFaceLoad (ys, sqin, compressiveStrength) {
      // Stnd Coup 45 Deg Max Bearing Face Load (ips)
      //= (F9*1000)*K9/1000
      // (KSI * 1000) * (Stnd Coup 45 Deg C.S.A (inch^2)) / 1000,
      // let n = (ksi * 1000) * sqin / 1000
      const n = ys * sqin * (compressiveStrength / 100) / 1000
      // return Math.round(n * 0.9, 0)
      return n * 0.9
    },
    calcJointStrengthSub (size, wt, ys, jteo) {
      // wt = Wall_Thickness = pipe_body_wall
      // ys = YIELD_STRENGTH_MIN
      // jte = YIELD_STRENGTH_MIN

      // = (Min Yield Strength) / 1000 * (Pipe Cross Section (inch^2)) * (Joint Tensile Efficency(%))/100
      // cross =PI()*(A7-C7)*C7
      //       pi() * ( (Size (inch)) - (Pipe Body Wall (inch)) ) * (Pipe Body Wall (inch))
      const cross = Math.PI * (size - wt) * wt
      const js = ys / 1000 * cross * jteo / 100

      return js
    },
    torque (torque, type = '') {
      let ksi = null
      if (Number(this.size.size) <= 4.5) {
        ksi = this.grade.gradeKsiLess
      } else {
        ksi = this.grade.gradeKsiGreater
      }
      return this.weight[this.frictionSelect.toLowerCase() + (this.grade.chrome ? 'Chrome' : 'Carbon') + 'Ksi' + ksi + torque + type]
    },
    frictionFactor () {
      switch (this.frictionSelect) {
        case 'Clr':
          if (this.grade.chrome) {
            this.frictionFactorType = 'ch'
            return 'Clear Run - High Chrome'
          } else {
            this.frictionFactorType = 'cc'
            return 'Clear Run - Carbon'
          }
        case 'Api':
          if (this.grade.chrome) {
            this.frictionFactorType = 'mh'
            return 'API Modified - High Chrome'
          } else {
            this.frictionFactorType = 'mc'
            return 'API Modified - Carbon'
          }
        default:
          return ''
      }
    },
    footNotesMethod () {
      var notes = []
      var noteIndex = 0
      this.sdInfo = null
      this.noteKeyTorque = null

      if (this.weightSelect) {
        if (this.weight.sd) {
          noteIndex++
          this.sdInfo = 'SD: ' + this.weight.sd + ' [**' + noteIndex + ']'
          notes.push({ index: noteIndex, type: 'sdInfo', descrption: 'SD is an identification code for distinguishing interchangeability.' })
        }
      }

      if (this.frictionSelect) {
        if ((this.frictionFactorType === 'mc') || (this.frictionFactorType === 'mh')) {
          noteIndex++
          this.noteKeyTorque = '[*' + noteIndex + ']'
          notes.push({ index: noteIndex, type: 'torqueInfo', descrption: 'Torque data shown is for API Modified (FF=1.0)' })
        }
        if ((this.frictionFactorType === 'cc') || (this.frictionFactorType === 'ch')) {
          noteIndex++
          this.noteKeyTorque = '[*' + noteIndex + ']'
          notes.push({ index: noteIndex, type: 'torqueInfo', descrption: 'Torque data shown is for Clear Run' })
        }
      }

      return notes
    },
    async saveHC () {
      if (this.gradeHcValue) {
        if (this.gradeHc) {
          this.gradeHc.hc = this.gradeHcValue
          await AdminApi.updateGradeHC(this.gradeHc.id, this.gradeHc)
        } else {
          this.gradeHc = {
            id: null,
            connectionId: this.connectionSelect,
            sizeId: this.sizeSelect,
            weightId: this.weightSelect,
            gradeId: this.gradeSelect,
            hc: this.gradeHcValue
          }

          this.gradeHc = await AdminApi.createGradeHC(this.gradeHc)
        }
      } else {
        if (this.gradeHc) {
          await AdminApi.deleteGradeHC(this.gradeHc.id)
          this.gradeHcValue = null
          this.gradeHc = null
        }
      }
      this.$refs.hcdd.toggle()
    },

    getInfoText (text) {
      switch (text) {
        case 'pipeId':
          return this.dataLanguages.pipe_id[this.languageSelect] + ' = ' + this.dataLanguages.size[this.languageSelect] + ' - (' + this.dataLanguages.pipe_body_wall[this.languageSelect] + ' * 2)'

        case 'driftDiameter':
          return '<div style="text-align: left"><small>' +
            'IF ' + this.dataLanguages.size[this.languageSelect] + ' <= 2.875 THEN ' +
            '<br>' + this.dataLanguages.drift_diameter[this.languageSelect] + ' = ' + this.dataLanguages.pipe_id[this.languageSelect] + ' - 3/32 ' +
            '<br>IF ' + this.dataLanguages.size[this.languageSelect] + ' < 9.625 THEN ' +
            '<br>' + this.dataLanguages.drift_diameter[this.languageSelect] + ' = ' + this.dataLanguages.pipe_id[this.languageSelect] + ' - 1/8 ' +
            '<br>IF ' + this.dataLanguages.size[this.languageSelect] + ' <= 13.375 THEN ' +
            '<br>' + this.dataLanguages.drift_diameter[this.languageSelect] + ' = ' + this.dataLanguages.pipe_id[this.languageSelect] + ' - 5/32 ' +
            '<br>ELSE ' +
            '<br>' + this.dataLanguages.drift_diameter[this.languageSelect] + ' = ' + this.dataLanguages.pipe_id[this.languageSelect] + ' - 3/16 ' +
            '</small></div>'

        case 'pipeCrossSection':
          return '<div style="text-align: left"><small>' +
            '&Pi; * ((' + this.dataLanguages.size[this.languageSelect] + ' / 2)<sup>2</sup> - (' + this.dataLanguages.pipe_id[this.languageSelect] + ' / 2)<sup>2</sup>)' +
            '</small></div>'

        case 'collapsePressure':
          return '<div style="text-align: left"><small>' +
            'wt=' + this.dataLanguages.pipe_body_wall[this.languageSelect] +
            '<br>ys=Yield Strength Min' +
            '<br>' +
            '<br>IF ys <= 24000' +
            '<br>' + this.dataLanguages.collapse_pressure[this.languageSelect] + ' = -1' +
            '<br>ELSE' +
            '<br>A = 2.8762 + 0.0000010679 * ys + 0.000000000021301 * ys<sup>2</sup> - 0.000000000000000053132 * ys<sup>3</sup> ' +
            '<br>B = 0.026233 + 0.00000050609 * ys' +
            '<br>C = -465.93 + 0.030867 * ys - 0.000000010483 * ys<sup>2</sup> + 0.000000000000036989 * ys<sup>3</sup>' +
            '<br>K = B / A ' +
            '<br>KKK = 3 * K / (2 + K)' +
            '<br>F = 46950000 * KKK<sup>3</sup> / (ys * ((KKK - K) * (1 - KKK)<sup>2</sup>))' +
            '<br>G = F * K' +
            '<br>DTyp = (((A - 2)<sup>2</sup> + 8 * (B + C / ys))<sup>0.5</sup> + A - 2) / (2 * (B + C / ys))' +
            '<br>DTpt = (ys * (A - F)) / (C + ys * (B - G))' +
            '<br>DTte = (2 + B / A) / (3 * B / A)' +
            '<br>DT = ' + this.dataLanguages.size[this.languageSelect] + ' / wt' +
            '<br>' +
            '<br>IF (DT <= DTyp)' +
            '<br>' + this.dataLanguages.collapse_pressure[this.languageSelect] + ' = 2 * ys * (DT - 1) / DT<sup>2</sup>' +
            '<br>IF (DT <= DTpt)' +
            '<br>' + this.dataLanguages.collapse_pressure[this.languageSelect] + ' = ys * (A / DT - B) - C' +
            '<br>IF (DT <= DTte)' +
            '<br>' + this.dataLanguages.collapse_pressure[this.languageSelect] + ' = ys * (F / DT - G)' +
            '<br>ELSE' +
            '<br>' + this.dataLanguages.collapse_pressure[this.languageSelect] + ' = 46950000 / (DT * (DT - 1)<sup>2</sup>)' +
            // '<br>====== RESULT =======' +
            // '<br>' + this.dataLanguages['collapse_pressure'][this.languageSelect] + ' = round(' + this.dataLanguages['collapse_pressure'][this.languageSelect] + '/10) * 10' +
            '</small></div>'

        case 'internalPressure':
          return '<div style="text-align: left"><small>' +
            this.dataLanguages.internal_yield_pressure[this.languageSelect] + ' = 2 * Yield Strength Min * ' +
             this.dataLanguages.pipe_body_wall[this.languageSelect] + ' / ' + this.dataLanguages.size[this.languageSelect] + ' * Guaranteed Wall' +
            '</small></div>'

        case 'pipeBodyYieldStrength':
          return '<div style="text-align: left"><small>' +
            'pipeArea = (&Pi; / 4) * (' + this.dataLanguages.size[this.languageSelect] + '<sup>2</sup> - ' + this.dataLanguages.pipe_id[this.languageSelect] + '<sup>2</sup>)' +
            '<br>' + this.dataLanguages.pipe_body_yield_strength[this.languageSelect] + ' = Yield Strength Min / 1000 * pipeArea' +
            '</small></div>'

        case 'maxBFLoad10':
          return '<div style="text-align: left"><small>' +
            'n = Yield Strength Min * Coupling BF Area * (Compression Strength / 100) / 1000' +
            '<br>' + this.dataLanguages.bearing_face_load[this.languageSelect] + ' = n * 0.9' +
            '</small></div>'

        case 'jointStrength':
          return '<div style="text-align: left"><small>' +
            'wt = ' + this.dataLanguages.pipe_body_wall[this.languageSelect] +
            '<br>ys = Yield Strength Min' +
            '<br>cross = &Pi; * (' + this.dataLanguages.size[this.languageSelect] + ' - wt) * wt' +
            '<br>' +
            'IF Coupling Selected = 101 THEN ' +
            '<br>&nbsp;&nbsp;jte = Coupling Selected JTE' +
            '<br>ELSE jte = Coupling Selected TE' +
            '<br>' + this.dataLanguages.joint_strength[this.languageSelect] + ' = ys / 1000 * cross * jte / 100' +
            '</small></div>'

        case 'compressionRating':
          return '<div style="text-align: left"><small>' +
            'jointX = (Weight Compression Rating / 100) * Compression Strength' +
            '<br>' + this.dataLanguages.compression_rating[this.languageSelect] + ' = (' + this.dataLanguages.joint_strength[this.languageSelect] + ' * (jointX / 100))' +
            '</small></div>'

        case 'maxBending':
          return '<div style="text-align: left"><small>' +
            'MaxBending = Weight Bending Modulus * (' + this.dataLanguages.joint_tensile_efficiency[this.languageSelect] + '/100) * (Compression Strength / 100)' +
            '</small></div>'

        default:
          return null
      }
    }
  }
}
</script>
<style lang="scss">
  @page {
    size: portrait;
  }
</style>

<style lang="scss" scoped>
  .model-viewer-container {
    height: 320px;
    overflow: hidden;
  }
  @media print {
    .model-viewer-container {
      height: auto;
    }
  }
</style>
